import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/acceso',
    name: 'access',
    component: () => import('../views/AcesssView.vue')
  },
  {
    path: '/registro',
    name: 'register',
    component: () => import('../views/RegisterView.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/eventos',
    name: 'events',
    component: () => import('../views/Events/EventsView.vue')
  },
  {
    path: '/eventos/nuevo',
    name: 'newevent',
    component: () => import('../views/Events/NewEventView.vue')
  },
  {
    path: '/miembros',
    name: 'members',
    component: () => import('../views/Member/MembersView.vue')
  },
  {
    path: '/miembro/nuevo',
    name: 'NewNembers',
    component: () => import('../views/Member/NewMemberView.vue')
  },
  {
    path: '/miembro/editar',
    name: 'EditMember',
    component: () => import('../views/Member/NewMemberView.vue'),
    props: true
  },
  {
    path: '/miembros/pendientes',
    name: 'EarringsMembers',
    component: () => import('../views/Member/MembersPendingsView.vue')
  },
  {
    path: '/miembros/ficha/:id',
    name: 'DataSheetMembers',
    component: () => import('../views/Member/DataSheetView.vue')
  },
  {
    path: '/eventos/lista',
    name: 'ListEvent',
    component: () => import('../views/Events/EventsListView.vue')
  },
  {
    path: '/ponente/nuevo/:id',
    name: 'NewSpeaker',
    component: () => import('../views/Speakers/NewSpeakerView.vue')
  },
  {
    path: '/ponente/editar',
    name: 'EditSpeaker',
    component: () => import('../views/Speakers/NewSpeakerView.vue'),
    props: true
  },
  {
    path: '/participante/nuevo/:id',
    name: 'NewParticipant',
    component: () => import('../views/Participant/NewParticipantView.vue')
  },
  {
    path: '/participante/editar',
    name: 'EditParticipant',
    component: () => import('../views/Participant/NewParticipantView.vue'),
    props: true
  },
  {
    path: '/ponente',
    name: 'Speaker',
    component: () => import('../views/Speakers/SpeakerView.vue'),
  },
  {
    path: '/participante',
    name: 'Participant',
    component: () => import('../views/Participant/ParticipantView.vue')
  },
  {
    path: '/ponente/ficha/:id',
    name: 'DataSheetSpeakers',
    component: () => import('../views/Speakers/DataSheetView.vue')
  },
  {
    path: '/eventos/editar/:id',
    name: 'editevent',
    component: () => import('../views/Events/EditEventView.vue')
  },
  {
    path: '/miembro/carnet/:id',
    name: 'carnet',
    component: () => import('../views/Member/CarnetView.vue')
  },
  {
    path: '/participante/ficha/:id',
    name: 'DataSheetParticipant',
    component: () => import('../views/Participant/DataSheetView.vue')
  },
  {
    path: '/CambiarContrasena',
    name: 'ChangePass',
    component: () => import('../views/ChangePassView.vue')
  },
  {
    path: '/CargarArchivos/:id',
    name: 'UploadFile',
    component: () => import('../views/Speakers/UploadFileView.vue')
  },
  {
    path: '/DescargarCarta/:id',
    name: 'DescargarCarta',
    component: () => import('../views/Speakers/LetterView.vue')
  },
  {
    path: '/DescargarCartaCo/:id',
    name: 'DescargarCartaCo',
    component: () => import('../views/Speakers/LetterCoautorView.vue')
  },
  {
    path: '/OlvideMiContra',
    name: 'OlvideMiContraseña',
    component: () => import('../views/ForgotPassword.vue')
  },
  {
    path: '/ActualizarContra/:id',
    name: 'ActualizarContra',
    component: () => import('../views/UpdatePass.vue')
  },
  {
    path: '/DescargarCartaInvitacion/:id',
    name: 'DescargarCartaInvitacion',
    component: () => import('../views/LetterInvitation.vue')
  },
  {
    path: '/DiplomaPonente/:id',
    name: 'DiplomaPonente',
    component: () => import('../views/Speakers/DiplomaPonente.vue')
  },
  {
    path: '/DiplomaParticipante/:id',
    name: 'DiplomaParticipante',
    component: () => import('../views/Participant/DiplomaParticipante.vue')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
